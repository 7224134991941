@import './colors.scss';

$itemsBackgroundLightColor: $alabaster;
$itemsBackgroundDarkColor: transparent;
$itemsContainerBorderLightColor: $gallery;
$itemsContainerBorderDarkColor: rgba($silverChalice, .25);

$itemsContainerBorderLight: 1px $itemsContainerBorderLightColor solid;
$itemsContainerBorderDark: 1px $itemsContainerBorderDarkColor solid;

.light {
    background-color: $itemsBackgroundLightColor;
    border-left: $itemsContainerBorderLight;
    border-right: $itemsContainerBorderLight;
}

.dark {
    background-color: $itemsBackgroundDarkColor;
    border-left: $itemsContainerBorderDark;
    border-right: $itemsContainerBorderDark;
}

.items-container {
    position: relative;
    flex-grow: 1;
    overflow-x: auto;
    overflow-y: hidden;
}

.items {
    position: absolute;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    right: 0;
    vertical-align: top;

    & > li {
        display: block;
    }
}

aside {
    .items {
        right: unset;
    }
}

.publish-indicator {
    display: none !important;
}