@import 'sulu-admin-bundle/containers/Application/colors.scss';

$previewBackgroundColor: $white;
$toolbarHeight: 60px;
$previewSmartphoneWidth: 431px;
$previewSmartphoneHeight: 887px;
$previewTabletWidth: 882px;
$previewTabletHeight: 1248px;

.container {
    height: 100%;
}

.auto {
    .iframe {
        height: 100%;
        width: 100%;
    }
}

.desktop {
    .iframe {
        height: 100%;
        width: 1024px;
    }
}

.tablet {
    .iframe-container {
        background-image: url('./tablet.png');
        background-size: $previewTabletWidth $previewTabletHeight;
        min-width: $previewTabletWidth;
        min-height: $previewTabletHeight;
    }

    .iframe {
        height: 1024px;
        width: 768px;
    }
}

.smartphone {
    .iframe-container {
        background-image: url('./smartphone.png');
        background-size: $previewSmartphoneWidth $previewSmartphoneHeight;
        min-width: $previewSmartphoneWidth;
        min-height: $previewSmartphoneHeight;
    }

    .iframe {
        height: 667px;
        width: 375px;
    }
}

.iframe {
    flex-shrink: 0;
    margin: auto;
    border: 0;
    background-color: $previewBackgroundColor;
    overflow: auto;
}

.iframe-container {
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    height: 100%;
    width: 100%;
    overflow: auto;
}

.preview-container {
    height: calc(100% - $toolbarHeight);
    overflow: auto;
}

.loader-container {
    width: 100%;
    height: 100%;
    padding-top: 100px;
}

.date-time-form {
    padding: 20px;
}
