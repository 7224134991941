@import 'sulu-admin-bundle/containers/Application/colors.scss';

$urlBackgroundColor: $white;
$urlBorderColor: $silver;
$urlButtonColor: $dustyGray;
$urlInputColor: $black;
$urlErrorBorderColor: $persianRed;
$urlHeight: 30px;
$urlBorderWidth: 1px;
$urlInputDisabledColor: $gray;
$urlInputDisabledBackgroundColor: $wildSand;

.url {
    display: flex;
    align-items: center;
    width: 100%;
    height: $urlHeight; 
    font-size: 12px;
    padding-left: 4px;
    padding-right: 4px;
    overflow: hidden;
}
