.image-focus-point {
    position: relative;
    display: inline-block;
    height: 100%;
}

.focus-points {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: wrap;
}

.image {
    max-width: 100%;
    max-height: 100%;
}
