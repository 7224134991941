@import 'sulu-admin-bundle/containers/Application/colors.scss';

$imageMapBackgroundColor: $white;
$imageMapBorderColor: $silver;
$imageMapBorderColorError: $persianRed;

.single-item-selection {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
}

.image-map {
    background-color: $imageMapBackgroundColor;
    border: 1px solid $imageMapBorderColor;
    border-radius: 0 0 3px 3px;
    width: 100%;
    overflow: hidden;
    padding: 30px 30px;

    &.error {
        border-color: $imageMapBorderColorError;
        border-top-color: $imageMapBorderColor;
    }
}

.form {
    margin-top: 30px;
}
