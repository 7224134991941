@import 'sulu-admin-bundle/containers/Application/colors.scss';

$buttonTextColor: $scorpion;
$buttonTextColorActive: $white;
$buttonBackgroundColor: $white;
$buttonBackgroundColorActive: $shakespeare;
$buttonBorderColor: $silver;
$buttonDisabledBackgroundColor: $wildSand;

.button {
    width: 40px;
    color: $buttonTextColor;
    background-color: $buttonBackgroundColor;
    border: 0;
    cursor: pointer;
    font-size: 14px;
    border-right: 1px solid $buttonBorderColor;

    &:hover {
        background-color: $buttonBackgroundColorActive;
        color: $buttonTextColorActive;
    }

    &:disabled {
        pointer-events: none;
        background-color: $buttonDisabledBackgroundColor;
    }
}
