@import 'sulu-admin-bundle/containers/Application/colors.scss';

$uploadIconColor: $scorpion;
$uploadInfoTextColor: $scorpion;
$uploadIndicatorBackgroundColor: rgba($alabaster, .8);
$imageBorderColor: $silver;
$emptyBackgroundColor: $white;
$emptyColor: $alto;
$errorTextColor: $persianRed;

/* $dropzoneSize + 2 * $viewPaddingHorizontal because calc doesn't work for media-queries */
$mediaContainerBreakpoint: 520px;

.media-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    background-image: url('./checkerBackground.gif');
    background-size: 10px;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid $imageBorderColor;

    &:hover,
    &.show-upload-indicator {
        .upload-indicator {
            opacity: 1;
            transform: scale(1);
        }
    }

    &::after {
        content: '';
        display: block;
        padding-top: 100%;
    }

    &.disabled {
        opacity: .5;
        cursor: default;

        .upload-indicator {
            opacity: 0;
        }
    }

    @media (max-width: $mediaContainerBreakpoint) {
        width: 100%;
    }
}

.progressbar,
.upload-indicator-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    text-align: center;
    background-color: $uploadIndicatorBackgroundColor;
    opacity: 0;
    transform: scale(.5);
    transition: all .25s cubic-bezier(.68, -.55, .265, 1.55);
}

.mime-type-indicator,
.empty-indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.empty-indicator {
    background-color: $emptyBackgroundColor;
    color: $emptyColor;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 120px;
}

.upload-icon {
    font-size: 45px;
    color: $uploadIconColor;
}

.upload-info-text {
    color: $uploadInfoTextColor;
    font-size: 14px;
    margin-top: 20px;
    padding: 0 20px;
}

.thumbnail {
    max-width: 100%;
}

.round {
    &.media-container {
        border-radius: 50%;
    }
}

.error-text {
    margin-top: 10px;
    color: $errorTextColor;
    text-align: center;
}
