@import 'sulu-admin-bundle/containers/Application/variables.scss';
@import 'sulu-admin-bundle/components/Navigation/variables.scss';
@import 'sulu-admin-bundle/components/Toolbar/variables.scss';

.loader {
    margin-top: calc($toolbarHeight + $viewPaddingHorizontal);
}

.root {
    width: calc(100vw + $navigationWidth);
    display: none;
    left: -$navigationWidth;
    position: absolute;
    transition: left $navigationMoveAnimationDuration;
    will-change: left;

    &.visible {
        display: inline-flex;
    }

    &.navigation-visible {
        left: 0;
    }
}

.content {
    display: flex;
    width: 100vw;
    min-height: 500px;
    will-change: width;
    transition: width $navigationMoveAnimationDuration;
    contain: layout;

    &.with-pinned-navigation {
        width: calc(100vw - $navigationWidth);
    }

    &.with-sidebar {
        display: flex;
    }
}

.view-container {
    display: block;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.main {
    width: 0;
    max-width: 100%;
    height: 100vh;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column-reverse;
    min-width: $viewMinWidth;
}

.sidebar {
    transition: flex $sidebarShrinkAnimationDuration;
    max-width: $sidebarMaxWidth;

    &.small {
        flex-basis: 30%;
    }

    &.medium {
        flex-basis: 50%;
    }

    &.hidden-preview {
        flex-basis: 0px;
    }

    &.large {
        flex-basis: $sidebarMaxWidth;
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.snackbar {
    max-width: $viewMaxWidth;
    transition: $navigationMoveAnimationDuration;

    &.small {
        right: 30%;
    }

    &.medium {
        right: 50%;
    }

    &.large {
        right: $sidebarMaxWidth;
    }

    &.isNavigationVisible {
        left: $navigationWidth;

        &.small {
            right: calc(30% - $navigationWidth);
        }

        &.medium {
            right: calc(50% - $navigationWidth);
        }

        &.large {
            right: calc(100% + $navigationWidth - $sidebarMaxWidth);
        }
    }

    &.isNavigationPinned {
        left: $navigationWidth;

        &.small {
            right: calc((100% - $navigationWidth) * .3);
        }

        &.medium {
            right: calc((100% - $navigationWidth) * .5);
        }

        &.large {
            right: calc(100% - $navigationWidth - $sidebarMaxWidth);
        }
    }
}

.navigation {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
}

button:disabled span[aria-label="su-en-plus-circle"]::before {
    content: url("../../../svg/add-icon.svg");
    filter: grayscale(100%);
}

span[aria-label="su-en-plus-circle"]::before {
    content: url("../../../svg/add-icon.svg");
}
