@import 'sulu-admin-bundle/components/Navigation/navigation.scss';

.navigation {
    .header {
        .logo {
            font-size: inherit;
            span::before {
                content: url("../svg/logo-white.svg");
            }
        }
    }
}