@import 'sulu-admin-bundle/containers/Application/colors.scss';

$hotspotsFormRendererBackgroundColor: $white;
$hotspotsFormRendererBorderColor: $silver;
$hotspotsFormRendererTabsBackgroundColor: transparent;
$hotspotsFormRendererEmptyTabsLabelColor: $scorpion;

.hotspots-form-renderer {
    background-color: $hotspotsFormRendererBackgroundColor;
    border: 1px solid $hotspotsFormRendererBorderColor;
    border-radius: 3px;
    width: 100%;
    overflow: hidden;
}

.toolbar {
    display: flex;
}

.empty-tabs-label {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-left: 10px;
    color: $hotspotsFormRendererEmptyTabsLabelColor;
}

.tabs {
    flex: 1 1 auto;
    margin-bottom: -1px;
}

.content {
    border-top: 1px solid $hotspotsFormRendererBorderColor;
    padding: 30px;
}

.settings {
    display: flex;
    align-items: flex-start;
    padding-bottom: 30px;

    .form {
        flex: 1 1 auto;
    }

    .remove-button {
        flex: 0 0 auto;
        margin-left: 30px;
        border: 0;
        background: none;
        cursor: pointer;

        &:disabled {
            pointer-events: none;
        }
    }
}
