@import 'sulu-admin-bundle/containers/Application/colors.scss';

$indicatorDraftBackgroundColor: $gold;
$indicatorPublishedBackgroundColor: $mantis;
$indicatorBorderColor: $white;
$indicatorDimension: 10px;
$indicatorOverlap: 10px;

.published {
    display: inline-block;
    border: 2px $indicatorBorderColor solid;
    border-radius: 50%;
    height: $indicatorDimension;
    width: $indicatorDimension;
}

.published {
    background-color: $indicatorPublishedBackgroundColor;
}
