@import 'sulu-admin-bundle/containers/Login/login.scss';

.login-container {
    .logo-container {
        span::before {
            content: url("../svg/logo.svg");
        }
    }

    .back-link {
        display: none;
    }
}
 