@import 'sulu-admin-bundle/containers/Application/colors.scss';

$snackbarHeight: 40px;

$snackbarErrorColor: $white;
$snackbarErrorBackgroundColor: $persianRed;
$snackbarWarningColor: $blueZodiac;
$snackbarWarningBackgroundColor: $gold;
$snackbarInfoColor: $white;
$snackbarInfoBackgroundColor: $blueZodiac;
$snackbarSuccessColor: $white;
$snackbarSuccessBackgroundColor: $mantis;

.snackbar {
    height: $snackbarHeight;
    padding: 0 20px;
    display: flex;
    align-items: center;
    will-change: margin-top;
    transition: margin-top .15s;
    width: 100%;

    &:not(.visible) {
        transition: margin-top .15s, visibility 0s .15s;
        visibility: hidden;
        margin-top: -$snackbarHeight;
    }
}

.floating {
    box-shadow: 2px 6px 12px 0 rgba($black, .5);
    border-radius: 3px;
    padding: 6px 7px;
    height: 32px;
    width: fit-content;

    .icon {
        font-size: 17px;
    }
}

.icon {
    padding-right: 10px;
    font-size: 20px;
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: center;
}

.text {
    font-size: 12px;
    flex-grow: 1;
}

.close-icon {
    font-size: 14px;
    font-weight: 900;
    padding: 3px;
}

.clickable {
    cursor: pointer;
}

.error {
    background-color: $snackbarErrorBackgroundColor;
    color: $snackbarErrorColor;

    .close-button {
        background-color: $snackbarErrorBackgroundColor;
        color: $snackbarErrorColor;
    }
}

.warning {
    background-color: $snackbarWarningBackgroundColor;
    color: $snackbarWarningColor;

    .close-button {
        background-color: $snackbarWarningColor;
        color: $snackbarWarningColor;
    }
}

.info {
    background-color: $snackbarInfoBackgroundColor;
    color: $snackbarInfoColor;

    .close-button {
        background-color: $snackbarInfoColor;
        color: $snackbarInfoColor;
    }
}

.success {
    background-color: $snackbarSuccessBackgroundColor;
    color: $snackbarSuccessColor;

    .close-button {
        background-color: $snackbarSuccessColor;
        color: $snackbarSuccessColor;
    }
}
