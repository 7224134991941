$marginBottom: 10px;

.label {
    display: flex;
    align-items: center;
    cursor: pointer;

    & > div {
        font-size: 12px;
        margin-left: 10px;
        overflow: hidden;
        line-height: 20px;
    }

    & + .label {
        margin-top: $marginBottom;
    }

    &.disabled {
        cursor: default;
    }
}

.label.small {
    & > div {
        font-size: 12px;
        margin-left: 8px;
    }
}

.switch {
    display: inline-block;
    position: relative;
    flex-shrink: 0;

    input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

    &.disabled {
        input {
            cursor: default;
        }
    }
}
