.image-renderer {
    display: flex;
    justify-content: center;
}

.image-renderer-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;

    &,
    * {
        user-select: none;
    }

    img {
        display: block;
        pointer-events: none;
    }
}

.image {
    width: auto;
    max-height: 500px;
    max-width: 100%;
}
