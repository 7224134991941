@import 'sulu-admin-bundle/containers/Application/variables.scss';
@import 'sulu-admin-bundle/components/Overlay/variables.scss';

.focus-point-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $viewPaddingVertical $viewPaddingHorizontal;
    height: $overlayContentMaxHeight;
}
